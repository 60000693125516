import { Environment } from 'src/app/shared/models/environment.interface';
import { baseEnvironment } from 'src/environments/baseEnvironment.preprod';

export const environment:Environment = {
  ...baseEnvironment,
  organizationPrefix: 'EMK',
  organizationUUID: '94d5ab68-a1e9-4c46-9745-bdad002a69dc',
  activeLanguage: 'it-IT',
  productVariantsUrl: 'https://product-variant.datalean-nodejs-preprod.catalean.com/product-variants/',
};
